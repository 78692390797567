<template>
  <!-- 人才简历-简历管理 -->
  <div class="talentBg">
    <section>
      <el-row class="talentIndex" :gutter="16">
        <el-col :span="8">
          <talentInfoPage></talentInfoPage>
        </el-col>
        <el-col :span="16">
          <!-- 简历信息部分 -->
          <div class="resumeTitle clearfix boxShadow">
            <!-- 头像 -->
            <div class="block talentAvd">
              <el-image
                style="width: 100px; height: 100px"
                :src="circleUrl"
              ></el-image>
              <el-button
                class="demonstration span-btn"
                style="margin: 5px 0px 5px 12px"
                v-prevent-repeat-click
                @click="refreshResume"
              >
                刷新简历
              </el-button>
            </div>
            <!-- 信息 -->
            <div class="talentInfo">
              <div>
                <span class="name">{{ userInfo.u_name }}</span>
              </div>
              <div class="baseInfo">
                <span>{{ userInfo.u_sex_text }}</span>
                <span>27岁</span>
                <span>
                  {{ talentResume.week_province }}-{{ talentResume.week_city }}
                </span>
                <span v-if="talentResume.work_experience == ''">
                  工作经验未填写
                </span>
                <span v-if="talentInfo.u_title == ''">
                  职称未填写
                </span>
                <span v-if="talentInfo.u_title != ''">
                  {{ talentInfo.u_title }}
                </span>
              </div>
              <div class="contact">
                <span>
                  <img :src="telIcon" alt="" />
                  <span>{{ userInfo.u_phone }}</span>
                </span>
                <span v-if="talentInfo.u_eamail == ''">
                  <img :src="emailIcon" alt="" />
                  <span>{{ talentInfo.u_eamail }}</span>
                </span>
              </div>
              <div class="down">
                <el-link @click="resumePre">预览简历</el-link>
                <el-link>下载简历</el-link>
              </div>
            </div>
          </div>
          <!-- 隐藏简历 -->
          <div class="resumeHidden">
            <div>
              <span style="font-weight: bold;">隐藏简历</span>
              <el-checkbox
                v-model="resHidden"
                style="float: right;"
                v-prevent-repeat-click
                @change="setResHidden"
              >
                隐藏简历
              </el-checkbox>
            </div>
            <p style="color: #999;padding-top:5px;">
              隐藏后，您的简历将不会推荐给HR，也无法被搜索到，只有您投递的企业可以正常查看您的简历
            </p>
          </div>
          <!-- 求职描述 -->
          <div class="resumeInfo resumeInfoList">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span style="font-size:17px;font-weight:bold;">求职描述</span>
                <i class="el-icon-edit editPencil" @click="editJobdesc"></i>
                <!-- <el-button
                  style="float: right; padding: 3px 0"
                  el-icon-edit
                ></el-button> -->
              </div>
              <div class="resume-item">
                {{ talentResume.job_description }}
              </div>
              <!-- 工作经历 -->
              <div class="el-card__header" style="margin-bottom: 0px">
                <div class="clearfix">
                  <span style="font-size:17px;font-weight:bold;">工作经历</span>
                  <el-button
                    style="float: right;"
                    size="mini"
                    @click="addWorkbtn"
                  >
                    添加
                  </el-button>
                </div>
              </div>
              <div class="resume-item">
                <div
                  class="clearfix workInfoBox"
                  v-for="(item, index) in talentResume.work_experience"
                  :key="index"
                >
                  <div class="infoLeft">
                    <p>
                      {{ item.com_name }}
                      ({{ item.work_time.toString().replace(",", " 至 ") }})
                    </p>
                    <p>
                      <span>工作类型:</span>
                      {{ item.titleType.toString().replace(",", "-") }}
                    </p>
                    <p>
                      <span>工作地点:</span>
                      {{ item.city }}
                    </p>
                    <p>
                      <span>工作内容:</span>
                      {{ item.info }}
                    </p>
                  </div>
                  <div class="infoRight">
                    <el-button
                      type="primary"
                      plain
                      icon="el-icon-edit"
                      v-prevent-repeat-click
                      @click="editWorkbtn(index)"
                    ></el-button>
                    <el-popconfirm
                      title="这是一段内容确定删除吗？"
                      @confirm="delWork(index)"
                    >
                      <el-button
                        type="danger"
                        plain
                        icon="el-icon-delete"
                        slot="reference"
                      ></el-button>
                    </el-popconfirm>
                  </div>
                </div>
              </div>
              <!-- 教育经历 -->
              <div class="el-card__header" style="margin-bottom: 0px">
                <div class="clearfix">
                  <span style="font-size:17px;font-weight:bold;">教育经历</span>
                  <el-button
                    style="float: right;"
                    size="mini"
                    @click="addEdubtn"
                  >
                    添加
                  </el-button>
                </div>
              </div>
              <div class="resume-item">
                <div
                  class="clearfix workInfoBox"
                  v-for="(item, index) in talentResume.educational_experience"
                  :key="index"
                >
                  <div class="infoLeft">
                    <p>
                      {{ item.school }}
                      ({{ item.edu_time.toString().replace(",", " 至 ") }})
                    </p>
                    <p>
                      <span>
                        {{ item.edu }}
                      </span>
                      <span>
                        {{ item.major }}
                      </span>
                    </p>
                  </div>
                  <div class="infoRight">
                    <el-button
                      type="primary"
                      plain
                      icon="el-icon-edit"
                      v-prevent-repeat-click
                      @click="editEdubtn(index)"
                    ></el-button>
                    <el-popconfirm
                      title="这是一段内容确定删除吗？"
                      @confirm="delEdu(index)"
                    >
                      <el-button
                        type="danger"
                        plain
                        icon="el-icon-delete"
                        slot="reference"
                      ></el-button>
                    </el-popconfirm>
                  </div>
                </div>
              </div>
              <!-- 项目名称 -->
              <div class="el-card__header" style="margin-bottom: 0px">
                <div class="clearfix">
                  <span style="font-size:17px;font-weight:bold;">项目名称</span>
                  <el-button
                    style="float: right;"
                    size="mini"
                    @click="addProjectbtn"
                  >
                    添加
                  </el-button>
                </div>
              </div>
              <div class="resume-item">
                <div
                  class="clearfix workInfoBox"
                  v-for="(item, index) in talentResume.project_experience"
                  :key="index"
                >
                  <div class="infoLeft">
                    <p>
                      {{ item.name }}
                      ({{ item.project_time.toString().replace(",", " 至 ") }})
                    </p>
                    <p>
                      <span>项目职责:</span>
                      {{ item.xmzz }}
                    </p>
                    <p>
                      <span>工作内容:</span>
                      {{ item.gznr }}
                    </p>
                  </div>
                  <div class="infoRight">
                    <el-button
                      type="primary"
                      plain
                      icon="el-icon-edit"
                      v-prevent-repeat-click
                      @click="editProjectbtn(index)"
                    ></el-button>
                    <el-popconfirm
                      title="这是一段内容确定删除吗？"
                      @confirm="delProject(index)"
                    >
                      <el-button
                        type="danger"
                        plain
                        icon="el-icon-delete"
                        slot="reference"
                      ></el-button>
                    </el-popconfirm>
                  </div>
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
      <!-- 编辑职位描述 -->
      <el-dialog
        :visible.sync="jobdesDialog"
        title="编辑职位描述"
        :close-on-click-modal="false"
        width="560px"
      >
        <el-input
          type="textarea"
          :rows="6"
          v-model="desInfo"
          maxlength="100"
          @input="descInput"
        ></el-input>
        <p class="text-right padding-top-5">
          您还可以输入<el-link type="primary">{{ destextLength }}</el-link> 字
        </p>
        <div slot="footer" class="dialog-footer">
          <el-button @click="jobdesDialog = false">取 消</el-button>
          <el-button
            type="primary"
            v-prevent-repeat-click
            @click="editJobdesPost"
          >
            确 定
          </el-button>
        </div>
      </el-dialog>
      <!-- 工作经历 -->
      <el-dialog
        title="工作经历"
        :visible.sync="workDialog"
        :close-on-click-modal="false"
        width="560"
      >
        <el-form
          :model="workInfo"
          ref="workInfo"
          label-width="110px"
          :rules="workInfoRules"
        >
          <el-form-item label="公司名称" prop="com_name">
            <el-input v-model="workInfo.com_name"></el-input>
            <el-checkbox v-model="comNameHidden">
              开启隐藏
            </el-checkbox>
          </el-form-item>
          <el-form-item label="工作地区" prop="city">
            <el-cascader
              size="large"
              :options="workInfoCity"
              v-model="selectedOptions"
              :placeholder="workInfo.city"
              @change="setWorkInfoCity"
              style="width: 100%;"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="职称类型" prop="titleType">
            <el-cascader
              style="width: 100%"
              v-model="workInfo.titleType"
              :options="selCerList"
              :props="{
                checkStrictly: true,
                value: 'category_name',
                label: 'category_name',
                children: 'child',
              }"
              filterable
              clearable
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="参加工作时间" prop="frist_work">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              v-model="workInfo.frist_work"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="职称" prop="title">
            <el-select
              v-model="workInfo.title"
              placeholder="请选择职称"
              style="width: 100%;"
            >
              <el-option label="无" value="无"></el-option>
              <el-option label="初级" value="初级"></el-option>
              <el-option label="中级" value="中级"></el-option>
              <el-option label="高级" value="高级"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属部门" prop="jobDep">
            <el-input v-model="workInfo.jobDep"></el-input>
          </el-form-item>
          <el-form-item label="在职时间" prop="work_time">
            <el-date-picker
              v-model="workInfo.work_time"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 100%;"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="工作内容" prop="info">
            <el-input
              type="textarea"
              :rows="3"
              v-model="workInfo.info"
              maxlength="100"
              @input="infoInput"
            ></el-input>
            <p class="text-right padding-top-5">
              您还可以输入<el-link type="primary">{{ destextLength }}</el-link>
              字
            </p>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="workDialog = false">取 消</el-button>
          <el-button
            type="primary"
            v-prevent-repeat-click
            @click="addWork"
            v-if="addWorkShow"
          >
            确 定
          </el-button>
          <el-button
            type="primary"
            v-prevent-repeat-click
            @click="editWork"
            v-if="editWorkShow"
          >
            确 定
          </el-button>
        </div>
      </el-dialog>
      <!-- 教育经历 -->
      <el-dialog
        title="教育经历"
        :visible.sync="eduDialog"
        :close-on-click-modal="false"
        width="560px"
      >
        <el-form
          :model="eduInfo"
          ref="eduInfo"
          label-width="110px"
          :rules="eduInfoRules"
        >
          <el-form-item label="学校名称" prop="school">
            <el-input v-model="eduInfo.school"></el-input>
          </el-form-item>
          <el-form-item label="学历" prop="edu">
            <el-select
              v-model="eduInfo.edu"
              placeholder="请选择学历"
              style="width: 100%;"
            >
              <el-option label="大专" value="大专"></el-option>
              <el-option label="本科" value="本科"></el-option>
              <el-option label="硕士" value="硕士"></el-option>
              <el-option label="博士" value="博士"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="专业" prop="major">
            <el-input v-model="eduInfo.major"></el-input>
          </el-form-item>
          <el-form-item label="在校时间" prop="edu_time">
            <el-date-picker
              v-model="eduInfo.edu_time"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 100%;"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="eduDialog = false">取 消</el-button>
          <el-button
            type="primary"
            v-prevent-repeat-click
            @click="addEdu"
            v-if="addEduShow"
          >
            确 定
          </el-button>
          <el-button
            type="primary"
            v-prevent-repeat-click
            @click="editEdu"
            v-if="editEduShow"
          >
            确 定
          </el-button>
        </div>
      </el-dialog>
      <!-- 项目经历 -->
      <el-dialog
        title="项目经历"
        :visible.sync="projectDialog"
        :close-on-click-modal="false"
        width="560px"
      >
        <el-form :model="projectInfo" ref="projectInfo" label-width="110px">
          <el-form-item label="项目时间" prop="project_time">
            <el-date-picker
              v-model="projectInfo.project_time"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 100%;"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="项目名称" prop="name">
            <el-input v-model="projectInfo.name"></el-input>
          </el-form-item>
          <el-form-item label="项目职责" prop="xmzz">
            <el-input
              v-model="projectInfo.xmzz"
              placeholder="您可以对您的项目职责情况进行描述"
              type="textarea"
              :rows="3"
            ></el-input>
          </el-form-item>
          <el-form-item label="工作内容" prop="gznr">
            <el-input
              v-model="projectInfo.gznr"
              placeholder="您可以对项目内容和项目的业绩情况进行描述"
              type="textarea"
              :rows="3"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="projectDialog = false">取 消</el-button>
          <el-button
            type="primary"
            v-prevent-repeat-click
            @click="addProject"
            v-if="addProjectShow"
          >
            确 定
          </el-button>
          <el-button
            type="primary"
            v-prevent-repeat-click
            @click="editProject"
            v-if="editProjectShow"
          >
            确 定
          </el-button>
        </div>
      </el-dialog>
    </section>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import talentInfoPage from "@/view/talentPage/talentInfo.vue";
import { provinceAndCityData, CodeToText } from "element-china-area-data";
export default {
  data() {
    return {
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      telIcon: require("@/assets/icon/tel.png"),
      emailIcon: require("@/assets/icon/email.png"),
      resHidden: false,
      jobdesDialog: false,
      desInfo: "",
      destextLength: "",
      workDialog: false,
      addWorkShow: false,
      editWorkShow: false,
      comNameHidden: false,
      workIndex: "",
      workInfo: {},
      selectedOptions: [],
      selCerList: [],
      workInfoCity: provinceAndCityData,
      eduDialog: false,
      addEduShow: false,
      editEduShow: false,
      eduInfo: {},
      eduIndex: "",
      projectDialog: false,
      addProjectShow: false,
      editProjectShow: false,
      projectInfo: {},
      projectIndex: "",
      workInfoRules: {
        com_name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        info: [{ required: true, message: "请输入工作内容", trigger: "blur" }],
        city: [{ required: true, message: "请选择社保地", trigger: "change" }],
        titleType: [
          { required: true, message: "请选择职称类型", trigger: "change" },
        ],
        work_time: [
          { required: true, message: "请选择时间", trigger: "change" },
        ],
      },
      eduInfoRules: {
        school: [
          { required: true, message: "请输入学校名称", trigger: "blur" },
        ],
        edu: [{ required: true, message: "请选择学历", trigger: "change" }],
        edu_time: [
          { required: true, message: "请选择时间", trigger: "change" },
        ],
      },
    };
  },
  components: {
    talentInfoPage,
  },
  computed: {
    ...mapState(["talentInfo", "talentCer", "talentResume"]),
    userInfo() {
      let k = localStorage.getItem("userInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  watch: {
    talentResume() {
      if (this.talentResume.hidden == 1) {
        this.resHidden = true;
      } else {
        this.resHidden = false;
      }
    },
  },
  methods: {
    ...mapMutations([
      "changetalentInfo",
      "changetalentCer",
      "changetalentResume",
    ]),
    resumePre() {
      this.$router.push({ path: "resumePre" });
    },
    /**
     * 刷新简历
     */
    refreshResume() {
      this.$http.post("talent/user/refresh").then((res) => {
        if (res.data.resultCode === 1) {
          this.$message.success("恭喜您，刷新成功！");
        }
      });
    },
    /**
     * 设置简历隐藏
     */
    setResHidden() {
      let hidden;
      if (this.resHidden) {
        hidden = 1;
      } else {
        hidden = 0;
      }
      this.$http.post("talent/user/hidden", { hidden: hidden }).then((res) => {
        if (res.data.resultCode === 1) {
          this.$message.success(res.data.resultMsg);
        }
      });
    },
    /**
     * 编辑职位描述
     */
    editJobdesc() {
      this.jobdesDialog = true;
      if (this.talentResume.job_description != undefined) {
        this.desInfo = this.talentResume.job_description;
      }
      this.descInput();
    },
    descInput() {
      this.destextLength = 100 - this.desInfo.length;
    },
    editJobdesPost() {
      this.$http
        .post("talent/user/jobdescription", { job_description: this.desInfo })
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.jobdesDialog = false;
            this.$message.success(res.data.resultMsg);
            // 简历求职描述修改后复现
            let info = this.talentResume;
            info.job_description = this.desInfo;
            this.changetalentResume({
              talentResume: info,
            });
          }
        });
    },
    /**
     * 工作经历
     */
    setWorkInfoCity() {
      let city =
        CodeToText[this.selectedOptions[0]] +
        "-" +
        CodeToText[this.selectedOptions[1]];
      this.workInfo.city = city;
    },
    infoInput() {
      this.destextLength = 100 - this.workInfo.info.length;
    },
    addWorkbtn() {
      this.selectedOptions = [];
      this.workInfo = {};
      this.destextLength = 100;
      this.addWorkShow = true;
      this.editWorkShow = false;
      this.workDialog = true;
    },
    // 职称下拉
    titleSelect() {
      this.$http.post("index/certificate/postion").then((res) => {
        if (res.data.resultCode == 1) {
          this.selCerList = res.data.resultData;
        }
      });
    },
    // 新增
    addWork() {
      let info = {};
      info.type = "add";
      info.work = this.talentResume.work_experience;
      if (this.comNameHidden) {
        this.workInfo.no_see = 1;
      } else {
        this.workInfo.no_see = 0;
      }
      info.work.push(this.workInfo);
      this.$http.post("talent/user/edwork", info).then((res) => {
        if (res.data.resultCode == 1) {
          this.$message.success(res.data.resultMsg);
          this.workDialog = false;
          // 简历工作经历复现
          let resume = this.talentResume;
          resume.work_experience = info.work;
          this.changetalentResume({
            talentResume: resume,
          });
        }
      });
    },
    // 删除
    delWork(e) {
      let workInfo = this.talentResume.work_experience;
      let info = {};
      info.type = "del";
      info.com_name = workInfo[e].com_name;
      workInfo.splice(e, 1);
      info.work = workInfo;
      this.$http.post("talent/user/edwork", info).then((res) => {
        if (res.data.resultCode == 1) {
          this.$message.success(res.data.resultMsg);
          let resume = this.talentResume;
          resume.work_experience = workInfo;
          this.changetalentResume({
            talentResume: resume,
          });
        }
      });
    },
    // 编辑
    editWorkbtn(e) {
      this.workIndex = e;
      let workInfo = this.talentResume.work_experience[e];
      this.workInfo = workInfo;
      if (this.workInfo.no_see == 1) {
        this.comNameHidden = true;
      } else {
        this.comNameHidden = false;
      }
      this.addWorkShow = false;
      this.editWorkShow = true;
      this.workDialog = true;
      this.infoInput();
    },
    editWork() {
      let info = {};
      info.type = "edit";
      info.work = this.talentResume.work_experience;
      if (this.comNameHidden) {
        this.workInfo.no_see = 1;
      } else {
        this.workInfo.no_see = 0;
      }
      info.work.splice(this.workIndex, 1, this.workInfo);
      this.$http.post("talent/user/edwork", info).then((res) => {
        if (res.data.resultCode == 1) {
          this.$message.success(res.data.resultMsg);
          this.workDialog = false;
          // 简历工作经历复现
          let resume = this.talentResume;
          resume.work_experience = info.work;
          this.changetalentResume({
            talentResume: resume,
          });
        }
      });
    },
    /**
     * 教育经历
     */
    addEdubtn() {
      this.addEduShow = true;
      this.editEduShow = false;
      this.eduInfo = {};
      this.eduDialog = true;
    },
    // 新增
    addEdu() {
      let info = {};
      info.type = "add";
      info.education = this.talentResume.educational_experience;
      info.education.push(this.eduInfo);
      this.$http.post("talent/user/ededucation", info).then((res) => {
        if (res.data.resultCode == 1) {
          this.$message.success(res.data.resultMsg);
          this.eduDialog = false;
          // 简历教育经历复现
          let resume = this.talentResume;
          resume.educational_experience = info.education;
          this.changetalentResume({
            talentResume: resume,
          });
        }
      });
    },
    // 删除
    delEdu(e) {
      let eduInfo = this.talentResume.educational_experience;
      let info = {};
      info.type = "del";
      eduInfo.splice(e, 1);
      info.education = eduInfo;
      this.$http.post("talent/user/ededucation", info).then((res) => {
        if (res.data.resultCode == 1) {
          this.$message.success(res.data.resultMsg);
          let resume = this.talentResume;
          resume.educational_experience = eduInfo;
          this.changetalentResume({
            talentResume: resume,
          });
        }
      });
    },
    //编辑
    editEdubtn(e) {
      this.eduIndex = e;
      let eduInfo = this.talentResume.educational_experience[e];
      this.eduInfo = eduInfo;
      this.addEduShow = false;
      this.editEduShow = true;
      this.eduDialog = true;
    },
    editEdu() {
      let info = {};
      info.type = "edit";
      info.education = this.talentResume.educational_experience;
      info.education.splice(this.eduIndex, 1, this.eduInfo);
      this.$http.post("talent/user/ededucation", info).then((res) => {
        if (res.data.resultCode == 1) {
          this.$message.success(res.data.resultMsg);
          this.eduDialog = false;
          // 简历教育经历复现
          let resume = this.talentResume;
          resume.educational_experience = info.education;
          this.changetalentResume({
            talentResume: resume,
          });
        }
      });
    },
    /**
     * 项目经历
     */
    addProjectbtn() {
      this.addProjectShow = true;
      this.editProjectShow = false;
      this.projectInfo = {};
      this.projectDialog = true;
    },
    addProject() {
      if (
        this.projectInfo.project_time == "" ||
        this.projectInfo.name == "" ||
        this.projectInfo.xmzz == "" ||
        this.projectInfo.gznr == "" ||
        this.projectInfo.project_time == undefined ||
        this.projectInfo.name == undefined ||
        this.projectInfo.xmzz == undefined ||
        this.projectInfo.gznr == undefined
      ) {
        this.$message.warning("请完善信息！");
        return;
      }
      let info = {};
      info.type = "add";
      info.project = this.talentResume.project_experience;
      info.project.push(this.projectInfo);
      this.$http.post("talent/user/edproject", info).then((res) => {
        if (res.data.resultCode == 1) {
          this.$message.success(res.data.resultMsg);
          this.projectDialog = false;
          // 简历项目经历复现
          let resume = this.talentResume;
          resume.project_experience = info.project;
          this.changetalentResume({
            talentResume: resume,
          });
        }
      });
    },
    // 删除
    delProject(e) {
      let projectInfo = this.talentResume.project_experience;
      let info = {};
      info.type = "del";
      projectInfo.splice(e, 1);
      info.project = projectInfo;
      this.$http.post("talent/user/edproject", info).then((res) => {
        if (res.data.resultCode == 1) {
          this.$message.success(res.data.resultMsg);
          let resume = this.talentResume;
          resume.project_experience = projectInfo;
          this.changetalentResume({
            talentResume: resume,
          });
        }
      });
    },
    // 编辑
    editProjectbtn(e) {
      this.projectIndex = e;
      let projectInfo = this.talentResume.project_experience[e];
      this.projectInfo = projectInfo;
      this.addProjectShow = false;
      this.editProjectShow = true;
      this.projectDialog = true;
    },
    editProject() {
      let info = {};
      info.type = "edit";
      info.project = this.talentResume.project_experience;
      info.project.splice(this.eduIndex, 1, this.projectInfo);
      this.$http.post("talent/user/edproject", info).then((res) => {
        if (res.data.resultCode == 1) {
          this.$message.success(res.data.resultMsg);
          this.projectDialog = false;
          // 简历项目经历复现
          let resume = this.talentResume;
          resume.project_experience = info.project;
          this.changetalentResume({
            talentResume: resume,
          });
        }
      });
    },
  },
  mounted() {
    this.titleSelect();
  },
};
</script>
<style scoped>
/* 简历个人信息 */
.resumeTitle {
  border-bottom: 1px solid #e8e8e8;
}
.talentIndex {
  padding-top: 24px;
}
.demonstration {
  margin-top: 10px;
}
.talentAvd {
  display: block;
  float: left;
}
.talentInfo {
  display: block;
  float: left;
  width: calc(100% - 136px);
  padding: 20px 10px;
}
.talentInfo .name {
  font-size: 17px;
  font-weight: bold;
  padding: 10px 0px;
  display: inline-block;
}
.talentInfo .baseInfo span {
  display: inline-block;
  padding: 10px 16px;
}
.talentInfo .baseInfo span:first-child {
  padding-left: 0;
}
.contact > span {
  padding: 10px 0;
  display: inline-block;
}
.contact span img {
  position: relative;
  top: 2px;
  padding-right: 6px;
}
.contact > span {
  padding-right: 20px;
}
.down {
  text-align: right;
}
.down a {
  margin-left: 16px;
}
/* 隐藏简历 */
.resumeHidden {
  margin: 16px 0;
  padding: 15px 20px;
  border-radius: 6px;
  background: #e6f7ff;
  border: 1px solid #91d5ff;
}
/* 求职描述 */
.editPencil {
  float: right;
  padding: 3px;
  font-size: 16px;
  color: #2573f1;
  cursor: pointer;
}
.resumeInfo .resume-item {
  padding: 20px;
  min-height: 50px;
}
/* 工作经历 */
.workInfoBox {
  padding: 6px 0px;
}
.workInfoBox p {
  padding: 5px 0;
}
.infoLeft,
.infoRight {
  float: left;
}
.infoLeft {
  width: 80%;
}
.infoLeft p span {
  color: #999;
}
.infoRight {
  width: 20%;
  text-align: right;
}
.infoRight button {
  margin-right: 10px;
}
.infoRight button {
  padding: 5px;
}
</style>
